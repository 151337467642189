import { useEffect, useState } from "react";

const Pagination = ({ page, totalPage, changePage }) => {
  const [pageButtons, setPageButtons] = useState([]);

  useEffect(() => {
    const start = parseInt(page) - 10 > 0 ? parseInt(page) - 10 : 0;
    const finish =
      parseInt(page) + 10 < parseInt(totalPage)
        ? parseInt(page) + 10
        : parseInt(totalPage);

    let array = [];
    for (let index = start + 1; index <= finish; index++) {
      array.push(index);
    }

    setPageButtons(array);
  }, [page, totalPage]);
  return (
    <footer className="w-full mt-auto sm:bg-white sm:fixed sm:left-0 sm:bottom-0 sm:shadow">
      <div className="flex w-full flex-wrap p-[10px] justify-center sm:p-0 xl:w-[1200px] xl:justify-start xl:m-auto">
        <button
          onClick={() => changePage(parseInt(page) - 1)}
          disabled={parseInt(page) <= 1}
          className="bg-white border h-[30px] px-[10px] rounded-l-[6px] disabled:bg-gray-100 disabled:text-gray-400"
        >
          Previous
        </button>

        {parseInt(page) - 10 > 0 ?
          <button
            onClick={() => changePage("1")}
            className="bg-white border h-[30px] px-[10px] "
          >
            1
          </button>
          : null
        }

        {
          parseInt(page) - 10 > 1 ?
            <div className="bg-gray-100 border text-gray-400 h-[30px] px-[10px]">
              ...
            </div>
            : null
        }

        {
          pageButtons.map(pageButton =>
            <button
              key={pageButton}
              onClick={() => changePage(pageButton)}
              disabled={parseInt(page) === pageButton}
              className={`border h-[30px] px-[10px] ${pageButton === parseInt(page)
                ? "text-white bg-teal-700 border-teal-700"
                : "bg-white"
                }`}
            >
              {pageButton}
            </button>
          )
        }

        {
          parseInt(page) + 11 < parseInt(totalPage) ?
            <div className="bg-gray-100 border text-gray-400 h-[30px] px-[10px]">
              ...
            </div>
            : null
        }

        {
          parseInt(page) + 10 < parseInt(totalPage) ?
            <button
              onClick={() => changePage(totalPage)}
              className="bg-white border h-[30px] px-[10px] "
            >
              {totalPage}
            </button>
            : null}

        <button
          onClick={() => changePage(parseInt(page) + 1)}
          disabled={parseInt(page) + 1 > parseInt(totalPage)}
          className="bg-white border h-[30px] px-[10px] rounded-r-[6px] disabled:bg-gray-100 disabled:text-gray-400"
        >
          Next
        </button>
      </div>
      <p className="bg-teal-700 text-white p-2 text-center text-[14px] sm:text-[16px]">
        Created by Fulabs for Pusintelad - copyright 2023, Bitbite
        optimization engine
      </p>
    </footer>
  );
};

export default Pagination;
