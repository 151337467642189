const NIKSkeleton = () => {
    return (
        <>
            <div className="flex gap-[10px] w-full">
                <span className="skeleton w-[20px] h-[20px] inline-block rounded-[6px]"></span>
                <span className="skeleton w-[180px] h-[20px] inline-block rounded-[6px]"></span>
                <span className="skeleton w-[50px] h-[20px] inline-block rounded-[6px]"></span>
            </div>
            <div className="flex gap-[10px] w-full my-[10px]">
                <span className="skeleton w-[20px] h-[20px] inline-block rounded-[6px]"></span>
                <span className="skeleton w-[180px] h-[20px] inline-block rounded-[6px]"></span>
                <span className="skeleton w-[50px] h-[20px] inline-block rounded-[6px]"></span>
            </div>
            <div className="flex gap-[10px] w-full">
                <span className="skeleton w-[20px] h-[20px] inline-block rounded-[6px]"></span>
                <span className="skeleton w-[180px] h-[20px] inline-block rounded-[6px]"></span>
                <span className="skeleton w-[50px] h-[20px] inline-block rounded-[6px]"></span>
            </div>
        </>
    )
}

export default NIKSkeleton