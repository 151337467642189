const UserListSkeleton = () => {
    return (
        <>
            <tr className="block bg-white w-full mb-[20px] border-b sm:table-row sm:border-b-0">
                <td className="block border w-full min-h-[40px] px-[15px] py-[20px] sm:table-cell sm:w-max">
                    <div className="h-[25px] w-[210px] rounded-[6px] skeleton"></div>
                </td>
                <td className="block border border-t-0 min-h-[40px] px-[15px] py-[20px] sm:table-cell">
                    <div className="h-[25px] w-[320px] rounded-[6px] skeleton"></div>
                </td>
                <td className="block border border-t-0 min-h-[40px] px-[15px] py-[20px] sm:table-cell">
                    <div className="h-[25px] w-[200px] rounded-[6px] skeleton"></div>
                </td>
                <td className="min-h-[40px] px-[15px] py-[10px] sm:border">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
                <td className="min-h-[40px] px-[15px] py-[10px] sm:border">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
            </tr>
        </>
    )
}

export default UserListSkeleton