import { usePopup } from "../contexts/Popup"

import { BsCheckCircle } from "react-icons/bs"

const SuccessPopup = ({ message, callback }) => {
    const popup = usePopup()

    const okeClicked = () => {
        popup.close()

        if (callback) setTimeout(() => {
            callback()
        }, 150);
    }

    return (
        <div className="bg-white shadow rounded-[6px] flex flex-col items-center py-[20px] px-[30px] gap-[20px] w-[320px]">
            <BsCheckCircle className="fill-teal-700 w-[100px] h-[100px]" />
            <div className="text-center">{message}</div>
            <button autoFocus={true} onClick={okeClicked} className="bg-teal-700 h-[40px] w-[150px] rounded-[6px] text-white">Oke</button>
        </div>
    )
}

export default SuccessPopup