import Navbar from "./Navbar"
import logoPusIntelAD from "../assets/logo-pushintelad.png"

import { NavLink } from "react-router-dom"
import { BsList } from "react-icons/bs"
import { useState } from "react"

const Header = () => {
    const [menuActive, setMenuActive] = useState(false)

    const menuButtonClick = () => {
        setMenuActive(true)
    }

    return (
        <header className="bg-white fixed top-0 w-screen border-b z-20">
            <div className="w-full h-[50px] flex justify-between items-center px-[10px] sm:h-[70px] sm:items-center sm:px-[20px] xl:px-0 xl:w-[1200px] xl:m-auto">
                <h1 className="text-teal-700 text-[25px]">
                    <NavLink to="/" className="flex items-center">
                        <img src={logoPusIntelAD} alt="Logo PUSINTELAD" className="h-[35px]" />
                        <span className="font-bold">Face</span>Recognition
                    </NavLink>
                </h1>
                <button onClick={menuButtonClick} className="sm:hidden"><BsList className="w-[35px] h-[35px]" /></button>
                <Navbar active={menuActive} setActive={setMenuActive} />
            </div>
        </header>
    )
}

export default Header