import { NavLink } from "react-router-dom"

const PageNotFound = () => {
    return (
        <main className="grid w-screen h-screen place-content-center">
            <div className="flex flex-col items-center gap-[15px] w-full p-[20px] sm:bg-white sm:shadow sm:w-[400px] sm:rounded-md sm:p-[30px]">
                <h2 className="font-bold text-xl text-teal-700">Opps!</h2>
                <h1 className="font-semibold text-teal-700">404 - Page Not Found</h1>
                <p className="text-center">The page you are looking for doesn't exit or an other error occured, go back to home</p>
                <NavLink to="/" className="bg-teal-700 h-[40px] rounded-md text-white grid place-content-center w-full">Go Back</NavLink>
            </div>
        </main>
    )
}

export default PageNotFound