const FaceListSkeleton = () => {
    return (
        <>
            <tr className="grid grid-cols-[120px_auto] bg-white w-full mb-[20px] border-b sm:table-row sm:border-b-0">
                <td className="border p-[10px] row-span-5 order-1">
                    <div className="h-[100px] w-[100px] rounded-[6px] skeleton sm:h-[200px] sm:w-[200px]"></div>
                </td>
                <td className="border p-[15px] order-7 col-span-2 text-center sm:text-left">
                    <div className="h-[25px] w-[300px] rounded-[6px] skeleton"></div>
                    <div className="h-[25px] w-[300px] rounded-[6px] skeleton my-[10px]"></div>
                    <div className="h-[25px] w-[300px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border p-[10px] order-2 sm:p-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border p-[10px] order-3 sm:p-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border p-[10px] order-4 sm:p-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border p-[10px] order-5 sm:p-[15px]">
                    <div className="h-[25px] w-[100px] rounded-[6px] skeleton"></div>
                </td>
                <td className="border p-[10px] sm:p-[15px] order-6 text-left">
                    <div className="h-[25px] m-auto w-[100px] rounded-[6px] skeleton"></div>
                </td>
            </tr>
        </>
    )
}

export default FaceListSkeleton