import { useReducer, useRef } from "react"

import { useToken } from "../contexts/Token"
import { usePopup } from "../contexts/Popup"

const AddUserPopup = ({ getList }) => {
    const refUsername = useRef()
    const refRTMPURL = useRef()
    const refAdminSecretToken = useRef()

    const [dataForm, dispatch] = useReducer(dataFormReducer, initialDataForm)

    const token = useToken()
    const popup = usePopup()

    const addUser = async (event) => {
        event.preventDefault()
        dispatch({ action: "startProcessing" })

        let error = {}
        if (!refUsername.current.value) error.username = "username should not be empty"
        if (!refRTMPURL.current.value) error.rtmp_url = "RTMP URL should not be empty"
        if (!refAdminSecretToken.current.value) error.tokenAdmin = "Admin token should not be empty"

        if (Object.keys(error).length) {
            dispatch({ action: "stopProcessing" })
            return dispatch({ action: "addErrorMessage", value: error })
        }

        try {
            await token.axiosJWT.post(process.env.REACT_APP_API_URL + "/admin/add_user", {
                username: refUsername.current.value,
                rtmp_url: refRTMPURL.current.value,
                admin_secret_token: refAdminSecretToken.current.value
            })
            dispatch({ action: "stopProcessing" })
            popup.close()
            getList()
        } catch (error) {
            dispatch({ action: "stopProcessing" })
            if (!error.response) return dispatch({ action: "addErrorMessage", value: { other: error.message } })
            if (!error.response.data.message) return dispatch({ action: "addErrorMessage", value: { other: error.response.data } })
            dispatch({ action: "addErrorMessage", value: error.response.data.message })
        }
    }

    const removeError = (event) => {
        if (event.target.value) dispatch({ action: "deleteSingleErrorMessage", part: event.target.name })
    }

    return (
        <form onSubmit={addUser} className="bg-white shadow rounded-[6px] py-[20px] px-[30px] w-[335px] flex flex-col items-center gap-[20px]">
            <h1 className="font-bold">Add New User</h1>
            <label className="w-full">
                <div>Username</div>
                <input type="text" name="username" onChange={removeError} ref={refUsername} className="border h-[40px] rounded-[6px] w-full px-[15px]" />
                {dataForm.errorMessage.username ? <div className="text-red-900">{dataForm.errorMessage.username}</div> : null}
            </label>
            <label className="w-full">
                <div>RTMP URL</div>
                <input type="text" name="rtmp_url" onChange={removeError} ref={refRTMPURL} className="border h-[40px] rounded-[6px] w-full px-[15px]" />
                {dataForm.errorMessage.rtmp_url ? <div className="text-red-900">{dataForm.errorMessage.rtmp_url}</div> : null}
            </label>
            <label className="w-full">
                <div>Admin Secret Token</div>
                <input type="password" name="admin_secret_token" onChange={removeError} ref={refAdminSecretToken} className="border h-[40px] rounded-[6px] w-full px-[15px]" />
                {dataForm.errorMessage.admin_secret_token ? <div className="text-red-900">{dataForm.errorMessage.admin_secret_token}</div> : null}
            </label>
            <button type="submit" disabled={dataForm.processing} className="bg-teal-700 text-white h-[40px] w-[100px] rounded-[6px]">
                {
                    dataForm.processing
                        ? <span className="border-[2px] border-white border-l-white/0 animate-spin rounded-full block w-[20px] h-[20px] m-auto"></span>
                        : <>Add User</>
                }
            </button>
        </form>
    )
}

export default AddUserPopup

const dataFormReducer = (dataForm, payload) => {
    switch (payload.action) {
        case "startProcessing": return { ...dataForm, processing: true }
        case "stopProcessing": return { ...dataForm, processing: false }
        case "addErrorMessage": return { ...dataForm, errorMessage: { ...dataForm.errorMessage, ...payload.value } }
        case "deleteAllErrorMessage": return { ...dataForm, errorMessage: {} }
        case "deleteSingleErrorMessage":
            let errorMessage = dataForm.errorMessage;
            delete errorMessage[payload.part]
            return { ...dataForm, errorMessage }
        default: throw Error("Unknown dataFormReducer action : " + payload.action)
    }
}

const initialDataForm = {
    processing: false,
    errorMessage: {
        name: "",
        RTMP: "",
        other: ""
    }
}