import { useEffect, useReducer } from "react"

import { useToken } from "../contexts/Token"
import { usePopup } from "../contexts/Popup"

import ErrorPopup from "../components/ErrorPopup"

import { useLocation, useNavigate } from "react-router-dom"
import UserListSkeleton from "../components/UserListSkeleton"
import Pagination from "../components/Pagination"
import AddUserPopup from "../components/AddUserPopup"
import { BsBoxArrowRight } from "react-icons/bs"

const UserList = () => {
    const [listData, dispatch] = useReducer(listDataReducer, initialListData)

    const token = useToken()
    const popup = usePopup()
    const location = useLocation()
    const navigate = useNavigate()

    const getList = async () => {
        dispatch({ action: "startProcessing" })
        try {
            const response = await token.axiosJWT.get(`${process.env.REACT_APP_API_URL}/admin/get_users?search=${listData.search}&page=${listData.page}`)
            dispatch({ action: "setList", list: response.data.data, totalPage: response.data.total_page })
            dispatch({ action: "stopProcessing" })
        } catch (error) {
            dispatch({ action: "stopProcessing" })
            popup.add(<ErrorPopup message={!error.response ? error.message : error.response.data} />)
        }
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [listData.search, listData.page])

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search)
        const search = urlParams.get("search") ? urlParams.get("search") : ""
        const page = urlParams.get("page") ? urlParams.get("page") : 1

        dispatch({ action: "setFilters", search, page, searchTemp: search })
        // eslint-disable-next-line
    }, [location.search])

    const search = (event) => {
        if (event.key === "Enter") navigate(`/user-list?search=${listData.searchTemp}&page=1`)
    }

    const changePage = (page) => {
        navigate(`/user-list?search=${listData.search}&page=${page}`)
    }

    const addUser = () => {
        popup.add(
            <AddUserPopup getList={getList} />
        )
    }

    const activate = async (id) => {
        try {
            await token.axiosJWT.put(`${process.env.REACT_APP_API_URL}/admin/activate_user/${id}`)
            getList()
        } catch (error) {
            popup.add(<ErrorPopup message={!error.response ? error.message : error.response.data} />)
        }
    }

    const deactivate = async (id) => {
        try {
            await token.axiosJWT.put(`${process.env.REACT_APP_API_URL}/admin/deactivate_user/${id}`)
            getList()
        } catch (error) {
            popup.add(<ErrorPopup message={!error.response ? error.message : error.response.data} />)
        }
    }

    const searchEnter = (event) => {
        dispatch({ action: "setSearchTemp", value: event.target.value })
    }

    return (
        <>
            <div className="flex gap-[10px] flex-wrap p-[10px] sm:py-[20px] sm:px-[20px] xl:px-0">

                <input type="text" onKeyUp={search} value={listData.searchTemp} onChange={searchEnter} placeholder="Search" className="block border w-full h-[40px] px-[15px] rounded-[6px] sm:w-[250px]" />

                <a href="http://stream.bitbite.id" target="_blank" rel="noreferrer" className="flex bg-teal-700 text-white w-max h-[40px] rounded-[6px] px-[15px] gap-[10px] items-center">Dashboard RTMP <BsBoxArrowRight /></a>

                <button onClick={addUser} className="block bg-teal-700 text-white h-[40px] px-[15px] rounded-[6px] ml-auto">Add User</button>
            </div>

            <table className="w-full sm:mb-[80px]">
                <thead className="hidden sm:table-header-group">
                    <tr>
                        <th className="border h-[40px] bg-gray-300 w-[300px]">ID</th>
                        <th className="border h-[40px] bg-gray-300">Name</th>
                        <th className="border h-[40px] bg-gray-300">RTMP URL</th>
                        <th className="border h-[40px] bg-gray-300 w-[120px]">Active</th>
                        <th className="border h-[40px] bg-gray-300 w-[120px]">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listData.processing
                            ? <UserListSkeleton />
                            : (
                                listData.list.length ?
                                    (
                                        listData.list.map(item => (
                                            <tr key={item.id} className="block bg-white w-full mb-[20px] border-b sm:table-row sm:border-b-0">
                                                <td className="block border w-full min-h-[40px] px-[15px] before:block before:content-['ID_:'] before:font-bold sm:table-cell sm:w-max sm:before:hidden">
                                                    {item.id}
                                                </td>
                                                <td className="block border border-t-0 min-h-[40px] px-[15px] before:block before:content-['Name_:'] before:font-bold sm:table-cell sm:before:hidden">
                                                    {item.username}
                                                </td>
                                                <td className="block border border-t-0 min-h-[40px] px-[15px] before:block before:content-['RTMP_URL_:'] before:font-bold sm:table-cell sm:before:hidden">
                                                    {item.rtmp_url}
                                                </td>
                                                <td className="min-h-[40px] px-[15px] py-[10px] before:content-['Active_:_'] before:font-bold before:inline-block before:mr-[20px] sm:border sm:before:hidden">
                                                    {item.active ? "Yes" : "No"}
                                                </td>
                                                <td className="min-h-[40px] px-[15px] py-[10px] sm:border">
                                                    {
                                                        item.active
                                                            ? <button onClick={() => deactivate(item.id)} className="h-[30px] rounded-[6px] text-white w-[100px] bg-red-800">Deactivate</button>
                                                            : <button onClick={() => activate(item.id)} className="h-[30px] rounded-[6px] text-white w-[100px] bg-teal-700">Activate</button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    :
                                    <tr>
                                        <td colSpan="5" className="h-[40px] border text-center">
                                            empty data
                                        </td>
                                    </tr>
                            )
                    }
                </tbody >
            </table >
            <Pagination totalPage={listData.totalPage} page={listData.page} changePage={changePage} />
        </>
    )
}

export default UserList

const listDataReducer = (listData, payload) => {
    switch (payload.action) {
        case "startProcessing": return { ...listData, processing: true }
        case "stopProcessing": return { ...listData, processing: false }
        case "setList": return { ...listData, list: payload.list, totalPage: payload.totalPage }
        case "setFilters": return { ...listData, search: payload.search, page: payload.page }
        case "setSearchTemp": return { ...listData, searchTemp: payload.value }
        default: throw Error("Unknown listDataReducer action : " + payload.action)
    }
}

const urlParams = new URLSearchParams(window.location.search)

const initialListData = {
    list: [],
    totalPage: 1,
    search: urlParams.get("search") ? urlParams.get("search") : "",
    page: urlParams.get("page") ? urlParams.get("page") : 1,
    processing: false,
    searchTemp: urlParams.get("search") ? urlParams.get("search") : ""
}