import { useEffect, useReducer, useState } from "react"
import { DateTime } from 'luxon'

import { useToken } from "../contexts/Token"
import { usePopup } from "../contexts/Popup"

import ErrorPopup from "../components/ErrorPopup"
import FaceListSkeleton from "../components/FaceListSkeleton"
import Pagination from "../components/Pagination"
import NIKSkeleton from "../components/NIKSkeleton"

import { useLocation, useNavigate } from "react-router-dom"
import { BsBoxArrowRight, BsTrashFill } from "react-icons/bs"

const FaceList = () => {
    const [intervalFunction, setIntervalFunction] = useState(null)
    const [listData, dispatch] = useReducer(listDataReducer, initialListData)

    const token = useToken()
    const popup = usePopup()
    const location = useLocation()
    const navigate = useNavigate()

    const getList = async () => {
        dispatch({ action: "startProcessing" })
        try {
            const loadData = async () => {
                const dateStart = DateTime.fromISO(listData.capturedStart).toUTC(0).toISO({ suppressMilliseconds: true })
                const dateEnd = DateTime.fromISO(listData.capturedEnd).toUTC(0).toISO({ suppressMilliseconds: true })

                const response = await token.axiosJWT.get(`${process.env.REACT_APP_API_URL}/admin/get_faces?search=${listData.search}&captured_start=${dateStart ? dateStart : ""}&captured_end=${dateEnd ? dateEnd : ""}&page=${listData.page}`)

                // const response = await token.axiosJWT.get(`${process.env.REACT_APP_API_URL}/admin/get_faces?search=${listData.search}&captured_start=${listData.capturedStart}&captured_end=${listData.capturedEnd}&page=${listData.page}`)
                dispatch({ action: "setList", list: response.data.data, totalPage: response.data.total_page })
                console.log("Data Loaded")
            }

            await loadData()

            dispatch({ action: "stopProcessing" })

            if (intervalFunction !== null) {
                clearInterval(intervalFunction)
            }

            setIntervalFunction(setInterval(() => {
                loadData()
            }, 3000))

        } catch (error) {
            dispatch({ action: "stopProcessing" })
            popup.add(<ErrorPopup message={!error.response ? error.message : error.response.data} />)
        }
    }

    useEffect(() => {
        return () => {
            if (intervalFunction !== null) {
                clearInterval(intervalFunction)
                setIntervalFunction(null)
            }
        }
    }, [intervalFunction])

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [listData.search, listData.page, listData.capturedStart, listData.capturedEnd])

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search)
        const search = urlParams.get("search") ? urlParams.get("search") : ""
        const capturedStart = urlParams.get("captured_start") ? urlParams.get("captured_start") : ""
        const capturedEnd = urlParams.get("captured_end") ? urlParams.get("captured_end") : ""
        const page = urlParams.get("page") ? urlParams.get("page") : 1

        dispatch({ action: "setFilters", search, page, searchTemp: search, capturedStart, capturedEnd })
        // eslint-disable-next-line
    }, [location.search])

    const search = (event) => {
        if (event.key === "Enter") navigate(`/face-list?search=${listData.searchTemp}&captured_start=${listData.capturedStart}&captured_end=${listData.capturedEnd}&page=1`)
    }

    const changeCapturedStart = (event) => {
        navigate(`/face-list?search=${listData.search}&captured_start=${event.target.value}&captured_end=${listData.capturedEnd}&page=1`)
    }

    const changeCapturedEnd = (event) => {
        navigate(`/face-list?search=${listData.search}&captured_start=${listData.capturedStart}&captured_end=${event.target.value}&page=1`)
    }

    const changePage = (page) => {
        navigate(`/face-list?search=${listData.search}&captured_start=${listData.capturedStart}&captured_end=${listData.capturedEnd}&page=${page}`)
    }

    const date = (date) => {
        const dateLuxon = DateTime.fromISO(date);
        const stringDateFormat = dateLuxon.setLocale("id").toFormat("DDDD 'pukul' HH:mm:ss ZZZZ");
        return stringDateFormat;
    }

    const getNIK = async (index, face_id) => {
        dispatch({ action: "addProcessingNIK", id: face_id })
        try {
            const response = await token.axiosJWT.post(process.env.REACT_APP_API_URL + "/getNik", {
                face_id
            })
            dispatch({ action: "removeProcessingNIK", id: face_id })
            dispatch({ action: "setNIK", index, nik: response.data.data })
        } catch (error) {
            popup.add(<ErrorPopup message={"Something went wrong with NIK service"} />)
            dispatch({ action: "removeProcessingNIK", id: face_id })
        }
    }

    const convertNIK = (nik) => {
        const list = JSON.parse(nik)

        return list.map(item => (
            <li key={item.nik} className="list-decimal mb-[10px]">{item.nik} <span className="bg-teal-700 text-white h-[30px] px-[10px] rounded-[6px]">{item.score} %</span></li>
        ))
    }

    const showNIK = (id) => {
        dispatch({ action: "showNIK", id })
    }

    const hideNIK = (id) => {
        dispatch({ action: "hideNIK", id })
    }

    const deleteFace = async (id) => {
        dispatch({ action: "setDeleting", id })
        await token.axiosJWT.delete(`${process.env.REACT_APP_API_URL}/admin/face/${id}`)
        getList()
        dispatch({ action: "setDeleting", id: null })
    }

    return (
        <>
            <div className="flex gap-[10px] flex-wrap p-[10px] sm:py-[20px] sm:px-[20px] xl:px-0">

                <input type="text" onKeyUp={search} value={listData.searchTemp} onChange={event => dispatch({ action: "setSearchTemp", value: event.target.value })} placeholder="Search" className="block border w-full h-[40px] px-[15px] rounded-[6px] sm:w-[250px]" />
                <span className="block w-full h-[40px] leading-[40px] sm:w-max sm:ml-[25px]">Date Captured :</span>
                <input type="datetime-local" value={listData.capturedStart} onChange={changeCapturedStart} className="border h-[40px] px-[15px] rounded-[6px]" /> - 
                <input type="datetime-local" value={listData.capturedEnd} onChange={changeCapturedEnd} className="border h-[40px] px-[15px] rounded-[6px]" />
                <a href="http://stream.bitbite.ai" target="_blank" rel="noreferrer" className="flex bg-teal-700 text-white h-[40px] rounded-[6px] px-[15px] items-center gap-[10px]">Stream Dashboard <BsBoxArrowRight /></a>
            </div>
            <table className="w-full sm:mb-[80px]">
                <thead className="hidden sm:table-header-group">
                    <tr>
                        <th className="bg-gray-300 border h-[40px] px-[15px]">Picture</th>
                        <th className="bg-gray-300 border h-[40px] px-[15px] w-[330px]">NIK</th>
                        <th className="bg-gray-300 border h-[40px] px-[15px]">Latitude</th>
                        <th className="bg-gray-300 border h-[40px] px-[15px]">Longitude</th>
                        <th className="bg-gray-300 border h-[40px] px-[15px]">Created By</th>
                        <th className="bg-gray-300 border h-[40px] px-[15px] w-[150px]">Captured At</th>
                        <th className="bg-gray-300 border h-[40px] px-[15px]">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listData.processing
                            ? <FaceListSkeleton />
                            : (
                                listData.list.length ?
                                    (
                                        listData.list.map((item, index) => (
                                            <tr key={item.id} className="grid grid-cols-[120px_auto] bg-white w-full mb-[20px] border-b sm:table-row sm:border-b-0">
                                                <td className="border p-[10px] row-span-5 order-1">
                                                    <img src={item.imageUrl} alt="face" className="w-[100px] h-[100px] sm:w-[200px] sm:h-[200px]" />
                                                </td>
                                                <td className="border p-[15px] order-7 col-span-2 text-center sm:text-left">
                                                    {
                                                        item.description
                                                            ? (
                                                                listData.visibleNIK.includes(item.id) ?
                                                                    <ul className="ml-[40px]">
                                                                        {convertNIK(item.description)}
                                                                        <button onClick={() => hideNIK(item.id)} className="text-teal-700 px-[10px] border border-teal-700 rounded-[6px]">Hide NIK</button>
                                                                    </ul>
                                                                    : <button onClick={() => showNIK(item.id)} className="text-teal-700 px-[10px] border border-teal-700 rounded-[6px]">Show NIK</button>
                                                            )
                                                            :
                                                            (
                                                                listData.processingNIK.includes(item.id)
                                                                    ? <NIKSkeleton />
                                                                    : <button onClick={() => getNIK(index, item.id)} className="bg-teal-700 h-[40px] px-[15px] rounded-[6px] text-white">Get NIK</button>
                                                            )

                                                    }
                                                </td>
                                                <td className="border p-[10px] order-2 before:content-['Latitude_:'] before:font-bold before:block sm:p-[15px] sm:before:hidden">{item.latitude ? item.latitude : "-"}</td>
                                                <td className="border p-[10px] order-3 before:content-['Longitude_:'] before:font-bold before:block sm:p-[15px] sm:before:hidden">{item.longitude ? item.longitude : "-"}</td>
                                                <td className="border p-[10px] order-4 before:content-['Created_By_:'] before:font-bold before:block sm:p-[15px] sm:before:hidden">{item.user.username ? item.user.username : "-"}</td>
                                                <td className="border p-[10px] order-5 before:content-['Captured_At_:'] before:font-bold before:block sm:p-[15px] sm:before:hidden">{item.createdAt ? date(item.createdAt) : "-"}</td>
                                                <td className="border p-[10px] sm:p-[15px] order-6 text-left">
                                                    <button disabled={item.id === listData.deleting} onClick={() => deleteFace(item.id)} className="bg-red-900 text-white px-[15px] h-[40px] rounded-[6px] w-[100px] flex items-center gap-[5px]">{item.id === listData.deleting ? <div className="animate-spin border-2 w-[20px] h-[20px] m-auto rounded-full border-l-white/30 border-b-white/30 border-t-white/30"></div> : <><BsTrashFill /> Delete</>}</button>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                    :
                                    <tr>
                                        <td colSpan="7" className="h-[40px] border text-center">
                                            empty data
                                        </td>
                                    </tr>
                            )
                    }
                </tbody >
            </table >
            <Pagination totalPage={listData.totalPage} page={listData.page} changePage={changePage} />
        </>
    )
}

export default FaceList

const listDataReducer = (listData, payload) => {
    switch (payload.action) {
        case "startProcessing": return { ...listData, processing: true }
        case "stopProcessing": return { ...listData, processing: false }
        case "setList": return { ...listData, list: payload.list, totalPage: payload.totalPage }
        case "setNIK": {
            let list = listData.list
            list[payload.index].description = JSON.stringify(payload.nik)
            return { ...listData, list }
        }
        case "setFilters": return { ...listData, search: payload.search, page: payload.page, capturedStart: payload.capturedStart, capturedEnd: payload.capturedEnd }
        case "setSearchTemp": return { ...listData, searchTemp: payload.value }
        case "showNIK": {
            let visibleNIK = listData.visibleNIK
            visibleNIK.push(payload.id)
            return { ...listData, visibleNIK }
        }
        case "hideNIK": {
            let visibleNIK = listData.visibleNIK
            delete visibleNIK[visibleNIK.indexOf(payload.id)]
            return { ...listData, visibleNIK }
        }
        case "addProcessingNIK": {
            let processingNIK = listData.processingNIK
            processingNIK.push(payload.id)
            return { ...listData, processingNIK }
        }
        case "setDeleting": return { ...listData, deleting: payload.id }
        case "removeProcessingNIK": {
            let processingNIK = listData.processingNIK
            delete processingNIK[processingNIK.indexOf(payload.id)]
            return { ...listData, processingNIK }
        }
        default: throw Error("Unknown listDataReducer action : " + payload.action)
    }
}

const urlParams = new URLSearchParams(window.location.search)

const initialListData = {
    list: [],
    totalPage: 1,
    processingNIK: [],
    visibleNIK: [],
    search: urlParams.get("search") ? urlParams.get("search") : "",
    capturedStart: urlParams.get("captured_start") ? urlParams.get("captured_start") : "",
    capturedEnd: urlParams.get("captured_end") ? urlParams.get("captured_end") : "",
    page: urlParams.get("page") ? urlParams.get("page") : 1,
    processing: false,
    deleting: null,
    searchTemp: urlParams.get("search") ? urlParams.get("search") : ""
}