import { useToken } from "../contexts/Token"
import Header from "../components/Header"
import PageNotFound from "../pages/PageNotFound"

const LoggedIn = ({ children }) => {
    const token = useToken()

    return token.access ? (
        <>
            <Header />
            <main className="flex flex-col w-full min-h-screen pt-[50px] sm:pt-[70px] xl:w-[1200px] xl:mx-auto">
                {children}
            </main>
        </>
    ) : (
        <PageNotFound />
    )
}

export default LoggedIn