import { createContext, useContext, useState } from "react"

import axios from "axios"
import jwtDecode from "jwt-decode"
import { useNavigate } from "react-router-dom"

const tokenContext = createContext()

export const useToken = () => useContext(tokenContext)

const TokenProvider = ({ children }) => {
    const [accessTokenState, setAccessTokenState] = useState(localStorage.getItem("access_token"))
    const [refreshTokenState, setRefreshTokenState] = useState(localStorage.getItem("refresh_token"))
    const navigate = useNavigate()

    const setAccessToken = (token) => {
        localStorage.setItem("access_token", token)
        setAccessTokenState(token)
    }

    const setRefreshToken = (token) => {
        localStorage.setItem("refresh_token", token)
        setRefreshTokenState(token)
    }

    const reset = () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        setAccessTokenState(null)
        setRefreshTokenState(null)
    }

    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async (config) => {
        let token = accessTokenState
        if ((jwtDecode(token).exp - 5) * 1000 < new Date().getTime()) {
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + "/admin/token", {
                    refresh_token: refreshTokenState
                })
                token = response.data.access_token
                setAccessToken(token)
            } catch (error) {
                if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                    reset()
                    navigate("/")
                }
                return Promise.reject(error)
            }
        }
        config.headers.Authorization = "Bearer " + token
        return config
    }, (error) => {
        return Promise.reject(error)
    })

    return (
        <tokenContext.Provider value={{ access: accessTokenState, refresh: refreshTokenState, setAccessToken, setRefreshToken, reset, axiosJWT }}>
            {children}
        </tokenContext.Provider>
    )
}

export default TokenProvider